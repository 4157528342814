@charset "utf-8";
* {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  outline: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.ant-btn-primary {
  background-color: #1a1a1a !important;
  border-color: #1a1a1a !important;
}
.ant-tabs-nav .ant-tabs-tab {
  margin: 0 16px 0 0 !important;
}
.ant-tabs-nav .ant-tabs-tab:hover,
.ant-tabs-nav .ant-tabs-tab:active,
.ant-tabs-nav .ant-tabs-tab-active {
  color: #1a1a1a !important;
}
.ant-tabs-ink-bar {
  background-color: #1a1a1a !important;
}
.ant-tabs-bar {
  margin: 0 0 32px 0 !important;
}
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: #fafafa !important;
  box-shadow: 0px -1px 0px 0px #eeeeee;
}
.ant-pagination-item-active a {
  color: #1a1a1a !important;
}
.ant-pagination-item-active {
  border-color: #1a1a1a !important;
}
@font-face {
  font-family: 'iconfont';
  src: url('//at.alicdn.com/t/font_288366_3btv85aycyx.eot');
  src: url('//at.alicdn.com/t/font_288366_3btv85aycyx.eot?#iefix') format('embedded-opentype'), url('//at.alicdn.com/t/font_288366_3btv85aycyx.woff2') format('woff2'), url('//at.alicdn.com/t/font_288366_3btv85aycyx.woff') format('woff'), url('//at.alicdn.com/t/font_288366_3btv85aycyx.ttf') format('truetype'), url('//at.alicdn.com/t/font_288366_3btv85aycyx.svg#iconfont') format('svg');
}
.iconfont {
  font-family: "iconfont" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
html,
body {
  background: #fafafa;
}
pre {
  margin: 0;
  padding: 0;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
}
#root {
  min-width: 1240px;
  height: 100vh;
  background: #f52d42;
}
.banner_search_box {
  position: absolute;
  bottom: 30px;
  z-index: 3;
}
.banner img {
  width: 100%;
}
.fixed-bottom {
  position: fixed;
  bottom: 0;
}
@font-face {
  font-family: hyq;
  src: url('./HYQiHei-55S_0.otf');
}
